import { PAGE_URLS, ACCOUNT_URLS } from '~/config/page-url'
import { PAGE_PARAMS } from '~/constants/common'
const PATH_IMAGE = '/assets/images/components/desktop/footer/'

export function getFooterContent() {
  const { $config } = useNuxtApp()

  const FOOTER_INFORMATION = [
    { title: 'Giới thiệu', link: PAGE_URLS.INTRODUCTION },
    { title: 'Bảo mật ', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.SECURITY}` },
    { title: 'Khuyến mãi', link: PAGE_URLS.PROMOTION },
    { title: 'Điều khoản - Điều kiện', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.POLICY}` },
    { title: 'Trợ giúp', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.SUPPORT}` },
    { title: 'Tin tức', link: PAGE_URLS.NEWS }
  ]

  const FOOTER_PAYMENT_METHOD = [
    { title: 'Ngân hàng', link: ACCOUNT_URLS.DEPOSIT_DA, icon: `${PATH_IMAGE}bank.svg` },
    { title: 'Ví điện tử', link: ACCOUNT_URLS.DEPOSIT_MOMO, icon: `${PATH_IMAGE}e-wallet.svg` },
    { title: 'Tiền ảo', link: ACCOUNT_URLS.DEPOSIT_CRYPTO, icon: `${PATH_IMAGE}crypto.svg` },
    { title: 'Thẻ cào', link: ACCOUNT_URLS.DEPOSIT_CARD, icon: `${PATH_IMAGE}card.svg` }
  ]

  const FOOTER_CONTENT = {
    SLOGAN_DESCRIPTION: 'Chúng tôi cung cấp cho bạn trải nghiệm cá cược hoàn toàn mới với hàng trăm sản phẩm cược như: Thể Thao, Virtual Sport, Virtual Casino và hệ thống ... ',
    VIEW_MORE: 'xem thêm',
    COPY_RIGHT: `Copyright © 2024 Powered By ${$config.BRANCH_NAME} All Rights Reserved.`
  }

  return {
    FOOTER_INFORMATION,
    FOOTER_PAYMENT_METHOD,
    FOOTER_CONTENT
  }
}

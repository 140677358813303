<template>
  <footer id="footer" class="section-footer">
    <div class="container-custom footer">
      <div class="footer__content">
        <div class="content-left">
          <nuxt-link :to="PAGE_URLS.HOME" class="logo">
            <BaseImg src="/assets/images/logo.svg" alt="logo" />
          </nuxt-link>
          <p class="content-left__desc">
            {{ FOOTER_CONTENT.SLOGAN_DESCRIPTION }}
            <nuxt-link class="view-more" :to="PAGE_URLS.INTRODUCTION">{{ FOOTER_CONTENT.VIEW_MORE }}</nuxt-link>
          </p>
        </div>
        <div class="content-center">
          <h3>THÔNG TIN {{ $config.BRANCH_NAME }}</h3>
          <div class="content-center__menus">
            <nuxt-link v-for="(item, index) in FOOTER_INFORMATION" :key="index" :to="item.link" class="item">
              {{ item.title }}
            </nuxt-link>
          </div>
        </div>
        <div class="content-right">
          <h3>Phương thức thanh toán</h3>
          <div class="content-right__methods">
            <nuxt-link
              v-for="(item, index) in FOOTER_PAYMENT_METHOD"
              :key="index"
              class="item"
              @click.prevent="handleClickMethod(item.link)"
            >
              <BaseImg :src="item.icon" alt="icon" class="item__icon" />
              <p class="item__title">{{ item.title }}</p>
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
    <div class="section-footer__bottom">
      <div class="contact">
        <a :href="`${$config.TELEGRAM}`" target="_blank" class="contact__item">
          <BaseImg src="/assets/images/components/common/telegram.svg" alt="telegram" />
          <p class="contact__item-title">Cộng đồng {{ $config.BRANCH_NAME }}</p>
        </a>
        <a :href="`${$config.TELEGRAM_247}`" target="_blank" class="contact__item">
          <BaseImg src="/assets/images/components/common/people.svg" alt="people" />
          <p class="contact__item-title">@CSKH{{ $config.BRANCH_NAME }} (Telegram 24/7)</p>
        </a>
      </div>
      <div class="copyright">{{ FOOTER_CONTENT.COPY_RIGHT }}</div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import { getFooterContent } from '~/constants/footer'
import useModal from '~/composables/useModal'
import { PAGE_URLS } from '~/config/page-url'
import { MODAL_TYPES } from '~/config/constant'

const { $config, $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser } = storeToRefs(store)

const { openModalWithNavigate } = useModal()
const { FOOTER_CONTENT, FOOTER_INFORMATION, FOOTER_PAYMENT_METHOD } = getFooterContent()

const handleClickMethod = (link: string) => {
  if (currentUser.value === null) {
    openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', link)
  } else {
    navigateTo(link)
  }
}
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/footer/index.scss" />

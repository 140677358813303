<template>
  <div id="header" class="header">
    <div class="header__container container-custom">
      <div class="header-menu">
        <nuxt-link id="logo" class="logo" :to="PAGE_URLS.HOME">
          <BaseImg :src="LOGO_SITE_IMAGE" alt="logo" />
        </nuxt-link>
        <client-only>
          <div class="menu">
            <nuxt-link
              v-for="(item, index) in menuList"
              :id="`link-${item?.name}`"
              :key="index"
              :to="item?.activeUrl"
              class="menu__item"
              :class="[
                item?.name,
                {
                  openSubMenu: openSubMenu === item?.name,
                  active: item?.name === route?.name
                }
              ]"
              @mouseover="onMouseOver(item)"
              @mouseleave="onMouseLeave()"
            >
              <BaseImg :src="item.icon" :alt="item.name" class="menu-icon--default" />
              <BaseImg :src="item.iconActive" :alt="item.name" class="menu-icon--active" />
              <span class="menu-title">{{ item.title }}</span>
              <span v-if="item.subMenu && item.subMenu.length" class="icon-menu-down" />
              <div v-if="item.subMenu && item.subMenu.length" class="sub-menu" :class="item?.name">
                <div class="sub-menu__container" :class="{ gridmenu: item.subMenu.length > 6 }">
                  <nuxt-link
                    v-for="(subItem, subIndex) in item.subMenu"
                    :id="`header-${subItem?.name}`"
                    :key="`${index}${subIndex}`"
                    class="sub-menu__item"
                    :class="[subItem?.type, subItem.alias, { active: subItem.url === route.fullPath }]"
                    @click.prevent="onClickSubMenu(subItem)"
                  >
                    <div class="sub-menu__item--content">
                      <BaseImg class="icon-active" :src="subItem.icon" :alt="subItem.display_name" />
                      <div class="text">
                        <p>{{ subItem.display_name }}</p>
                        <div v-if="subItem.jackpot > 0" class="jackpot">
                          <AnimateNumber class="value" :number="subItem.jackpot" />
                          <span>K</span>
                        </div>
                      </div>
                    </div>
                    <div v-if="!subItem?.isNotShowLine" class="line" />
                  </nuxt-link>
                </div>
              </div>
            </nuxt-link>
          </div>
        </client-only>
      </div>
      <div class="header-button">
        <client-only>
          <UserNotLogin v-if="!currentUser" />
          <UserLogged v-else />
        </client-only>
        <div id="notification-header" class="header-button__notif">
          <BaseImg
            id="bell"
            class="header-button__notif--bell"
            src="/assets/images/components/desktop/icon-notif.svg"
            alt="bell"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { LOGO_SITE_IMAGE } from '~~/constants/menu'
import BaseImg from '~/components/common/base-img.vue'
import { useAppStore } from '@/store/app'
import { MENUS } from '~/constants/menu'
import UserNotLogin from '~/components/desktop/header/user-not-login.vue'
import UserLogged from '~/components/desktop/header/user-logged.vue'
import AnimateNumber from '~/components/common/animate-number.vue'
import { IMenuHeader, ISubMenuHeader } from '~/types/menu.type'
import { useJackpot } from '~/composables/game/useJackpot'
import { useGameStore } from '~~/store/game'
import { useGame } from '~/composables/game/useGame'
import { useCasino } from '~/composables/casino/useCasino'
import { PAGE_URLS } from '~/config/page-url'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot, currentUser, isLogged } = storeToRefs(store)
const storeGame = useGameStore($pinia)
const { providerGames, providerCasino } = storeToRefs(storeGame)
const openSubMenu = ref('')
const { fetchSumJackpot } = useJackpot()
const { fetchCongGame } = useGame()
const { fetchGameCasino } = useCasino()
const route = useRoute()
const onMouseOver = (item: IMenuHeader) => {
  if (item.subMenu && item.subMenu.length) {
    openSubMenu.value = item?.name
  }
}
const onMouseLeave = () => {
  openSubMenu.value = ''
}

const fetchData = () => {
  if (providerGames.value.length === 0) {
    fetchCongGame()
  }
  if (providerCasino.value.length === 0) {
    fetchGameCasino()
  }
}

const onClickSubMenu = (item: ISubMenuHeader) => {
  navigateTo(`${PAGE_URLS.GAME}/${item.alias}`)
}

onMounted(() => {
  fetchSumJackpot()
  fetchData()
})

const menuList = reactive(MENUS)

const getSumJackpot = (aliasMenu: string) => {
  switch (aliasMenu) {
    case 'no-hu': {
      return sumJackpot.value.jackpotNohu
    }
    case 'ban-ca': {
      return sumJackpot.value.jackpotFishing
    }
    case 'table-game': {
      return sumJackpot.value.jackpotFishing
    }
    default: {
      return 0
    }
  }
}

watchEffect(() => {
  const updateSubMenu = (submenu: ISubMenuHeader[], type: string) => {
    const targetItem = menuList.find((item) => item.type === type)
    if (targetItem && submenu?.length) {
      const subMenuUpdated = submenu.map((item) => {
        return {
          ...item,
          jackpot: getSumJackpot(item.alias)
        }
      })
      targetItem.subMenu = subMenuUpdated
    }
  }

  const subMenusToUpdate = [
    { submenu: providerGames.value, type: 'games' }
    // { submenu: providerCasino.value, type: 'livecasino' }
  ]
  subMenusToUpdate.forEach(({ submenu, type }) => updateSubMenu(submenu, type))
})
</script>

<style scoped lang="scss" src="assets/scss/components/desktop/header/index.scss"></style>
